import React, { useState, useEffect, } from 'react';
import { useNavigate } from "react-router-dom";

import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import ModeEmploiGenealogie from './Docs/sfanm site généalogique.docx';
import EncodageReines from './Docs/sfanm codage des pedigrees.docx';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useSelector, useDispatch } from 'react-redux'
import MenuBar from './components/MenuBar'

import { setTitreBar } from './store/displayslice'

import { login, retrieveApiculteurTotal, postPasswordReset } from './userModule/services/authentification';
import { userStore } from './store/userslice';


function HomeScreen() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

    /* const {user} = useSelector(state => state.user);
  var langue = '';
  if (user) {
    langue = user.langage ;
  } else
  {
    console.log('appel navigate');
    navigate("/reine");
  } */
  const { user } = useSelector(state => state.user);
  const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;

  useEffect(() => {
    console.log('homme sreeen - useeffect');
    if (!user) {
      if (token) {
        Promise.all([retrieveApiculteurTotal(),])
          .then(async ([userValues,]) => {
            console.log(userValues);
            dispatch(userStore(userValues));
            //dispatch(apiProprietaireStore (userValues.id));
            //dispatch(setAffVisite(false));

            //dispatch(setAffReine(true));
            //toggleAff();
            //navigate('/reine');
          })


      }


    }

  }, []);


  dispatch(setTitreBar('RUCHERS'));

  const cardModeEmploiReine = (
    <>
      <CardContent>
        <Typography variant="h5" component="div">
          Mode d'emploi
        </Typography>
      </CardContent>
      <CardActions>

        <a href={ModeEmploiGenealogie} download="Genealogie.docx">Télécharger</a>
      </CardActions>
    </>
  );

  const cardEncodageReines = (
    <>
      <CardContent>
        <Typography variant="h5" component="div">
          Encodage
        </Typography>
      </CardContent>
      <CardActions>

        <a href={EncodageReines} download="Encodage.docx">Télécharger</a>
      </CardActions>
    </>
  );

  const cardGenealogie = (
    <>
                <CardActionArea onClick={() => { navigate('/reine') }}>

      <CardContent>
        <Typography variant="h5" component="div" align="center">
          Généalogie <br />.
        </Typography>
      </CardContent>
      </CardActionArea>

    </>
  );

  const cardAccesColonies = (
    <>
            <CardActionArea onClick={() => { navigate('/colonies') }}>

      <CardContent>
        <Typography variant="h5" component="div" align="center">
          Je gère mes ruches <br /> par colonies
        </Typography>
      </CardContent>
      </CardActionArea>
    </>
  );

  const cardAccesRuchers = (
    <>
        <CardActionArea onClick={() => { navigate('/ruchers') }}>

      <CardContent>
        <Typography variant="h5" component="div" align="center">
          Je gère mes ruches <br/> par rucher
        </Typography>
      </CardContent>
      </CardActionArea>

    </>
  );

  const cardDelegues = (
    <>
        <CardActionArea onClick={() => { navigate('/delegues') }}>

      <CardContent>
        <Typography variant="h5" component="div" align="center">
          Je gère mes délégués
        </Typography>
      </CardContent>
      </CardActionArea>

    </>
  );

  const cardJournal = (
    <>
    <CardActionArea onClick={() => { navigate('/journal') }}>
      <CardContent>
        <Typography variant="h5" component="div"  >
          Journal
        </Typography>
      </CardContent>


      </CardActionArea>
    </>
  );

  const cardBiometrie = (
    <>
    <CardActionArea onClick={() => { navigate('/biometrie') }}>
      <CardContent>
        <Typography variant="h5" component="div"  >
          Biométrie
        </Typography>
      </CardContent>


      </CardActionArea>
    </>
  );

  return (
    <>
      <MenuBar />
      <Grid2 container>

        <Grid2 xs={4} >
          <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
            <div style={{ height: 400, width: '100%' }}>
              <Typography variant="h4" align="center">
                Gestion des ruchers
              </Typography>
              <br></br>
              <p>Suivi et gestion des colonies</p>
              <Stack direction="row" spacing={2} alignItems="center">
                <Card sx={{ m: 1, bgcolor: amber[50] }} variant="outlined">{cardAccesColonies}</Card>
                <Card sx={{ m: 1, bgcolor: amber[50] }} variant="outlined">{cardAccesRuchers}</Card>
                <Card sx={{ m: 1, bgcolor: amber[50] }} variant="outlined">{cardDelegues}</Card>
              </Stack>
              <p>Nécessite de créer un compte pour pouvoir enregistrer toute l'activité</p>
            </div>
          </Paper>
        </Grid2>
        <Grid2 xs={4} >
          <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
            <div style={{ height: 400, width: '100%' }}>
              <Typography variant="h4" align="center">
                Généalogie
              </Typography>

              <br></br>
              <p> Enregistrement des généalogies des abeilles</p>
              <Stack direction="row" spacing={2} alignItems="center">
                <Card sx={{ m: 1, bgcolor: amber[50] }} variant="outlined">{cardGenealogie}</Card>
                <Card sx={{ m: 1, bgcolor: amber[50] }} variant="outlined">{cardModeEmploiReine}</Card>
                <Card sx={{ m: 1, bgcolor: amber[50] }} variant="outlined">{cardEncodageReines}</Card>
              </Stack>
              <p>Il est nécessaire de créer un compte pour pouvoir créer et modifier les caractéristiques des reines</p>
              <p>l'accès en consultation est libre</p>
              <br />

            </div>
          </Paper>
        </Grid2>
        <Grid2 xs={4} >
          <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
            <div style={{ height: 400, width: '100%' }}>
              <Typography variant="h4" align="center">
                Journal
              </Typography>

              <br></br>
              <p> Prenez des notes</p>
              <Stack direction="row" spacing={2} alignItems="center">
                <Card sx={{ m: 1, bgcolor: amber[50] }} variant="outlined">{cardJournal}</Card>

              </Stack>
              <p>Il est nécessaire de créer un compte </p>
              <br />

            </div>
          </Paper>
        </Grid2>
        <Grid2 xs={4} >
          <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
            <div style={{ height: 400, width: '100%' }}>
              <Typography variant="h4" align="center">
                Biométrie
              </Typography>

              <br></br>
              <p> Biométrie</p>
              <Stack direction="row" spacing={2} alignItems="center">
                <Card sx={{ m: 1, bgcolor: amber[50] }} variant="outlined">{cardBiometrie}</Card>

              </Stack>
              <p>Il est nécessaire de créer un compte </p>
              <br />

            </div>
          </Paper>
        </Grid2>        

      </Grid2>

    </>
  );
}


export default HomeScreen;


