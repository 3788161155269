import { get, options, put, post, del } from '../../services/fetch';


export const getListRace = () => {
  return get('/ListRace');
};

export const getListRuchers = () => {
  return get('/ListRucher');
};

export const getListTypeRuches = () => {
  return get('/ListTypRuche');
};

export const getListMarquage = async (langue) => {

  switch (langue) {
    case 'en':
      return get('/ListMarquageEn');
      break;
    default:
      return get('/ListMarquage');
      break;
  };
};

export const getOptionsColonie = (idColonie) => {
  return options('/DetColonieV2/' + idColonie);
};

export const getListTypPb = async (langue) => {

  switch (langue) {
    case 'en':
      return get('/ListTypPbEn');
      break;
    default:
      return get('/ListTypPb');
      break;
  };

};



export const getListTypCR = async (langue) => {

  switch (langue) {
    case 'en':
      return get('/ListTypCREn');
      break;
    default:
      return get('/ListTypCR');
      break;
  };
};


export const getListTypMedoc = () => {
  return get('/ListTypMedoc');
};

export const getListTypNourri = () => {
  return get('/ListTypNourri');
};

export const getListUniNourri = async (langue) => {

  switch (langue) {
    case 'en':
      return get('/ListUniNourriEn');
      break;
    default:
      return get('/ListUniNourri');
      break;
  };
};

//Rucher

export const getListRuchersDeApi = (idApi) => {
  return get('/ListRucherDeApi?idApi=' + idApi);
};

export const getListRuchersDelegue = () => {
  return get('/ListRucherDelegue');
};

export const createRucher = async (apiculteur, rucher) => {

  let nom = rucher.nom;
  let emplacement = rucher.emplacement;
  return post('/NewRucher', {
    apiculteur, nom, emplacement,
  }
  )
};


export const modifyRucher = async (apiculteur, rucher) => {
  let idRucher = rucher.id;
  let nom = rucher.nom;
  let emplacement = rucher.emplacement;
  return put('/DetRucher/' + idRucher, {
    apiculteur, nom, emplacement,
  }
  )
};

export const modMultiRuchers = async (lesRuchers) => {
 
  return put('/DetRucherMulti' , {lesRuchers});
 };

export const delRucher = async (idRucher) => {
  return del ('/DetRucher/' + idRucher );
};

export const getDetRucher = (idRucher) => {
  return get('/DetRucher/' + idRucher );
};

// Colonie
export const getColoniesDuRucher = (idRucher) => {
  return get('/ListColoniesRucherV2?rucher=' + idRucher );
};

export const getFirstColonie = () => {
  return get('/FirstColonie');
};

export const listColoniesdeApi = (idApi) => {
  return get('/ListColoniesDeApi?idApi=' + idApi);
};


export const listColoniesDelegation = (idApi) => {
  return get('/ListColoniesDelegation?idApi=' + idApi);
};

export const getDetColonie = (idColonie) => {
  return get('/DetColonieV2/' + idColonie);
};

export const modifyColonie = async (Colonie) => {
  let nom = Colonie.nom;
  let emplacement = Colonie.emplacement || null;
  let rucher = Colonie.rucher;
  let date = Colonie.date;
  let typeruche = Colonie.typeruche;
  let marquage = Colonie.marquage;
  let numreine = Colonie.numreine || null;
  let nomreine = Colonie.nomreine || null;
  //let originereine = null ;
  let originereine = Colonie.originereine || null;
  let fecondationreine = Colonie.fecondationreine || null;
  let ancetrereine = Colonie.ancetrereine || null;
  console.log(Colonie.filiation);
  console.log(Colonie.filiation.len);
  let filiation = null;
  if (Colonie.filiation.len == 0 || Colonie.filiation == "null" || Colonie.filiation == "") {
    filiation = null
  } else {
    filiation = Colonie.filiation
  } ;
  //let filiation = Colonie.filiation || null;
  let race = Colonie.race;
  return put('/DetColonieV2/' + Colonie.id, {
    nom, emplacement, rucher, date, typeruche, marquage, numreine, nomreine, originereine,
    fecondationreine, ancetrereine, filiation, race,
  });
};

export const modifyColonieDel = async (Colonie) => {
  let nom = Colonie.nom;
  let rucher = Colonie.rucher;
  let date = Colonie.date;
  let typeruche = Colonie.typeruche;
  let marquage = Colonie.marquage;
  let numreine = Colonie.numreine|| null; 
  let nomreine = Colonie.nomreine  || null;
  let originereine = null ;
  originereine = Colonie.originereine  || null;
  let fecondationreine = Colonie.fecondationreine || null ;
  let ancetrereine = Colonie.ancetrereine  || null;
  let filiation = null;

  if (Colonie.filiation == null || Colonie.filiation.len == 0 || Colonie.filiation == "null" ||  Colonie.filiation == "") {
    filiation = null
  } else {
    filiation = Colonie.filiation
  } ;
  let  race = Colonie.race ;
 let datesuppression = Colonie.datesuppression;
  let motifsuppression = Colonie.motifsuppression;
  return put('/DetColonieV2/' + Colonie.id , {nom ,rucher ,date , typeruche , marquage ,numreine , nomreine, originereine ,
    fecondationreine , ancetrereine, filiation , race , datesuppression ,motifsuppression });
};


export const createColonie = async (Colonie) => {
  let nom = Colonie.nom;
  let emplacement = Colonie.emplacement || null;

  let rucher = Colonie.rucher;
  //   date = Colonie.date.toLocaleDateString("fr-FR");
  //   date = Moment(Colonie.date).format('YYYY-MM-DD');
  let date = Colonie.date;
  let typeruche = Colonie.typeruche;
  let marquage = Colonie.marquage;
  let numreine = Colonie.numreine || null;
  //let originereine = null ;
  let originereine = Colonie.originereine || null;
  //   fecondationreine = Colonie.fecondationreine ;
  //   if (Colonie.fecondationreine.len == 0) {fecondationreine = null} else {fecondationreine = Colonie.fecondationreine};
  let fecondationreine = Colonie.fecondationreine || null;
  //   fecondationreine = null ;
  //   ancetrereine = null ;
  let ancetrereine = Colonie.ancetrereine || null;
  let filiation = null;
  if (Colonie.filiation.len == 0 || Colonie.filiation == "null" || Colonie.filiation == "") {
    filiation = null
  } else {
    filiation = Colonie.filiation
  } ;
  let race = Colonie.race;
  return post('/NewColonieV2', {
    nom, emplacement, rucher, date, typeruche, marquage, numreine, originereine,
    fecondationreine, ancetrereine, filiation, race,
  });
};

export const delColonie = async (idColonie) => {
  return del ('/DetColonie/' + idColonie );
};

export const modMultiColonies = async (lesColonies) => {
 
  return put('/DetColonieMulti' , {lesColonies});
 };

 export const getColoniePhoto = (idColonie) => {
  return get('/DetColoniePhoto?idColonie=' + idColonie);
};
 
// Travaux possibles
export const getListTravaux = () => {
  return get('/ListTravaux');
};

// Travaux faits
export const createTravaux = async (lesTravFaits, idVisite) => {

  return post('/CreateTravaux', { lesTravFaits, idVisite });
};

export const getListTravauxFaits = (idVisite) => {
  return get('/ListTravauxFaits?idVisite=' + idVisite);
};

// Travaux a faire
export const createTravauxAFaire = async (lesTravAFaire, idColonie) => {

  return post('/CreateTravauxAFaire', { lesTravAFaire, idColonie });
};

export const getListTravauxAFaire = (idColonie) => {
  return get('/ListTravauxAFaire?idColonie=' + idColonie);
};

// Description des hausses
export const createHausseVisites = async (lesHausses, idVisite) => {

  let lesHausseVisites = [];
  lesHausses.map(hausse => {
    const lHausse = new Object();
    hausse.NbCadres == "" ? lHausse.NbCadres = null : lHausse.NbCadres = parseInt(hausse.NbCadres);
    { hausse.NbCadresABatir == "" ? lHausse.NbCadresABatir = null : lHausse.NbCadresABatir = parseInt(hausse.NbCadresABatir) };
    { hausse.NbCadresRecoltes == "" ? lHausse.NbCadresRecoltes = null : lHausse.NbCadresRecoltes = parseInt(hausse.NbCadresRecoltes) };
    { hausse.NbCadresMielEnCours == "" ? lHausse.NbCadresMielEnCours = null : lHausse.NbCadresMielEnCours = parseInt(hausse.NbCadresMielEnCours) };
    { hausse.NbCadresMielOpercule == "" ? hausse.NbCadresMielOpercule = null : lHausse.NbCadresMielOpercule = parseInt(hausse.NbCadresMielOpercule) };
    { hausse.observation == "" ? lHausse.observation = null : lHausse.observation = hausse.observation };

    lesHausseVisites.push(lHausse);
  }

  );
  
  return post('/CreateHausseVisites', { lesHausseVisites, idVisite });
};

export const getListHausseVisites = (idVisite) => {
  return get('/ListHausseVisites?idVisite=' + idVisite);
};


export const getListHausseVisitesColonie = (idColonie) => {
  return get('/ListHausseVisitesColonie?idColonie=' + idColonie);
};

export const getLastHausseVisites = (idColonie) => {
  return get('/LastHausseVisites?idColonie=' + idColonie);
};

export const getLastHausseVisitesRucher = (idRucher) => {
  return get('/LastHausseVisitesRucher?idRucher=' + idRucher);
};

// Visite
export const getOptionsVisite = (idVisite) => {
  return options('/DetVisiteV2/' + idVisite);
};

export const getFirstVisite = () => {
  return get('/FirstVisite');
};

export const delVisite = async (Visite) => {
  console.log(Visite);
  return del('/DetVisiteV2/' + Visite.id);
};

export const getLastVisite = (idColonie) => {
  return get('/LastVisiteV2?idColonie=' + idColonie);
};

export const createVisite = async (Visite) => {
  const Moment = require('moment');
  //   console.log(Visite);

  let date = Visite.date;
  let colonie = Visite.colonie;
  let ouverture = Visite.ouverture;
  let pres_oeuf = Visite.pres_oeuf;
  let pres_couv_ouv = Visite.pres_couv_ouv;
  let pres_CR = Visite.pres_CR || null;
  let pres_couv_ferm = Visite.pres_couv_ferm;
  let pres_cel_male = Visite.pres_cel_male;
  let comportement = null;
  {( Visite.comportement == "" || Visite.comportement < 0) ? comportement = null : comportement = parseInt(Visite.comportement)} ;

  let dern_cad_couvain = null;
  {(Visite.dern_cad_couvain == "" ||Visite.dern_cad_couvain  < 0) ? dern_cad_couvain = null :  dern_cad_couvain = parseInt(Visite.dern_cad_couvain)} ;


  let pres_males = Visite.pres_males;
  let meteo = Visite.meteo || null;
  let nbelts = null;
  {(Visite.nbelts == "" || Visite.nbelts < 0) ? nbelts = null : nbelts = parseInt(Visite.nbelts)} ;

  let observations = Visite.observations || null;
  let problem1 = null;
  {(Visite.problem1 == "" || Visite.problem1 < 0) ? problem1 = null : problem1 = parseInt(Visite.problem1)} ;

  let problem2 = null;
  {(Visite.problem2 == "" || Visite.problem2 < 0) ? problem2 = null : problem2 = parseInt(Visite.problem2)} ;

  let poids = Visite.poids || null;
  let prem_cad_couvain = null;
  {(Visite.prem_cad_couvain == "" || Visite.prem_cad_couvain < 0) ? prem_cad_couvain = null : prem_cad_couvain  = parseInt(Visite.prem_cad_couvain)} ;

  let pres_reine = Visite.pres_reine;
  let ruelles = null;
  {(Visite.ruelles == "" || Visite.ruelles < 0) ? ruelles  = null : ruelles = parseInt(Visite.ruelles)} ;

  let sante = null;
  {(Visite.sante == "" || Visite.sante < 0) ? sante  = null :sante  = parseInt(Visite.sante)} ;

  let tenuecadre = null;
  {(Visite.tenuecadre == "" || Visite.tenuecadre  < 0) ? tenuecadre = null : tenuecadre = parseInt(Visite.tenuecadre)} ;

  let travauxafaire = Visite.travauxafaire || null;
  let travauxrealises = Visite.travauxrealises || null;
  let intervenant = Visite.intervenant || null;
  let ChampLibre1 = Visite.ChampLibre1 || null;
  let ChampLibre2 = Visite.ChampLibre2 || null;

  let NbCadresABatir = null;
  { Visite.NbCadresABatir == "" ? NbCadresABatir = null : NbCadresABatir = parseInt(Visite.NbCadresABatir) };

  let EtatReserves = null;
  { Visite.EtatReserves == "" ? EtatReserves = null : EtatReserves = parseInt(Visite.EtatReserves) };
  
  let HaussesDetaillees = Visite.HaussesDetaillees || null;;


  //   return post ('/NewVisite' , {date, colonie, pres_oeuf, pres_couv_ouv, pres_couv_ferm, pres_reine,
  //          pres_CR, pres_cel_male, pres_males, prem_cad_couvain, dern_cad_couvain, ruelles,
  //          problem1, problem2, observations, travauxafaire, travauxrealises, meteo, nbelts,
  //          poids, comportement, tenuecadre, sante});
  return post('/NewVisiteV2', {
    date, colonie, ouverture, pres_oeuf, pres_couv_ouv, pres_couv_ferm, pres_reine,
    pres_CR, pres_cel_male, pres_males, prem_cad_couvain, dern_cad_couvain, ruelles,
    problem1, problem2, observations, travauxafaire, travauxrealises, meteo, nbelts,
    poids, comportement, tenuecadre, sante, intervenant, ChampLibre1, ChampLibre2,
    EtatReserves, HaussesDetaillees, NbCadresABatir

  });
};

export const modifyVisite = async (Visite) => {
  console.log(Visite);
  let date = Visite.date;
  let colonie = Visite.colonie;
  let ouverture = Visite.ouverture;
  let pres_oeuf = Visite.pres_oeuf;
  let pres_couv_ouv = Visite.pres_couv_ouv;
  let pres_CR = Visite.pres_CR || null;
  let pres_couv_ferm = Visite.pres_couv_ferm;
  let pres_cel_male = Visite.pres_cel_male;
  let comportement = null;
  {(Visite.comportement == "" || Visite.comportement < 0) ? comportement  = null : comportement = parseInt(Visite.comportement)} ;

  let dern_cad_couvain = null;
  {(Visite.dern_cad_couvain == "" || Visite.dern_cad_couvain < 0) ? dern_cad_couvain = null : dern_cad_couvain = parseInt(Visite.dern_cad_couvain)} ;

  let pres_males = Visite.pres_males;
  let meteo = Visite.meteo || null;
  let nbelts = null;
  {(Visite.nbelts == "" ||Visite.nbelts  < 0) ? nbelts  = null : nbelts = parseInt(Visite.nbelts)} ;

  let observations = Visite.observations || null;
  let problem1 = null;
  {(Visite.problem1 == "" || Visite.problem1 < 0) ? problem1 = null : problem1  = parseInt(Visite.problem1)} ;

  let problem2 = null;
  {(Visite.problem2 == "" || Visite.problem2  < 0) ? problem2 = null : problem2  = parseInt(Visite.problem2)} ;

  let poids = Visite.poids || null;
  let prem_cad_couvain = null;
  {(Visite.prem_cad_couvain == "" || Visite.prem_cad_couvain < 0) ? prem_cad_couvain  = null : prem_cad_couvain = parseInt(Visite.prem_cad_couvain)} ;

  let pres_reine = Visite.pres_reine;
  let ruelles = null;
  {(Visite.ruelles == "" || Visite.ruelles < 0) ? ruelles = null : ruelles = parseInt(Visite.ruelles)} ;

  let sante =  null;
  {(Visite.sante == "" || Visite.sante < 0) ? sante = null : sante = parseInt(Visite.sante)} ;


  let tenuecadre =  null;
  {(Visite.tenuecadre == "" || Visite.tenuecadre < 0) ? tenuecadre  = null : tenuecadre = parseInt(Visite.tenuecadre)} ;


  let travauxafaire = Visite.travauxafaire || null;
  let travauxrealises = Visite.travauxrealises || null;
  let intervenant = Visite.intervenant || null;
  let ChampLibre1 = Visite.ChampLibre1 || null;
  let ChampLibre2 = Visite.ChampLibre2 || null;

  let NbCadresABatir = null;
  { Visite.NbCadresABatir == "" ? NbCadresABatir = null : NbCadresABatir = parseInt(Visite.NbCadresABatir) };

  let EtatReserves = null;
  { Visite.EtatReserves == "" ? EtatReserves = null : EtatReserves = parseInt(Visite.EtatReserves) };
  let HaussesDetaillees = Visite.HaussesDetaillees ;;

  console.log(sante);

  return put('/DetVisiteV2/' + Visite.id, {
    date, colonie, ouverture, pres_oeuf, pres_couv_ouv, pres_couv_ferm, pres_reine,
    pres_CR, pres_cel_male, pres_males, prem_cad_couvain, dern_cad_couvain, ruelles,
    problem1, problem2, observations, travauxrealises, travauxafaire, meteo, nbelts,
    poids, comportement, tenuecadre, sante, intervenant, ChampLibre1, ChampLibre2,
    EtatReserves, HaussesDetaillees, NbCadresABatir

  });
};

export const getListVisite = (idColonie) => {
  return get('/ListVisiteV2?idColonie=' + idColonie);
};

// ---- Observation 

export const getListObservation = (idColonie) => {
  return get('/ListObservation?idColonie=' + idColonie);
};

export const createObservation = async (Observation) => {
   let date = Observation.date;
   let colonie = Observation.colonie ;
   let observations = null ;
   let sante = null ;
   {Observation.observations == "" ? observations = null : observations = Observation.observations} ;
   {Observation.sante == "" || Observation.sante < 0 ? sante = null : sante = parseInt(Observation.sante)} ;
   return post ('/NewObservation' , {date, colonie, observations, sante});
};

export const modifyObservation = async (Observation) => {
   let date = Observation.date;
//  nbkgmiel = Recolte.nbkgmiel;
   let colonie = Observation.colonie;
   let observations = null ;
   let sante = null ;   
   {Observation.observations == "" ? observations = null : observations = Observation.observations} ;
   {Observation.sante == "" || Observation.sante < 0 ? sante = null : sante = parseInt(Observation.sante)} ;
   
  
  return put('/DetObservation/' + Observation.id , {date, colonie, observations, sante});
};

export const delObservation = async (Observation) => {
   return del ('/DetObservation/' + Observation.id );
};



// soin
export const getLastSoin = (idColonie) => {
  return get('/LastSoin?idColonie=' + idColonie);
};

export const getListSoin = (idColonie) => {
  return get('/ListSoin?idColonie=' + idColonie);
};

export const delSoin = async (Soin) => {
  //   const Moment = require('moment');

  //   apiculteur = await getIdApiculteur();

  return del('/DetSoin/' + Soin.id);
};


export const createSoin = async (Soin) => {
  const Moment = require('moment');
  //   console.log('ruchers - create soin');
  //   console.log(Soin);
  let date = Soin.date;
  let colonie = Soin.colonie;

  let qtmedicament = Soin.qtmedicament || null;
  let medicament = null;
  { Soin.medicament == "" || Soin.medicament <= 0 ? medicament = null : medicament = Soin.medicament };
  let qtnourrissment = Soin.qtnourrissment || null;
  let unitenourrissement = null;
  { Soin.unitenourrissement == "" || Soin.unitenourrissement <= 0 ? unitenourrissement = null : unitenourrissement = Soin.unitenourrissement };
  let typnourrissement = Soin.typnourrissement || null;
  { Soin.typnourrissement == "" ? typnourrissement = null : typnourrissement = Soin.typnourrissement };
  let nourrissement = Soin.nourrissement || null;
  { Soin.nourrissement == "" || Soin.nourrissement <= 0 ? nourrissement = null : nourrissement = Soin.nourrissement };

  return post('/NewSoin', {
    date, colonie, qtmedicament, medicament, qtnourrissment,
    unitenourrissement, typnourrissement, nourrissement
  });
};

export const modifySoin = async (Soin) => {
  let date = Soin.date;
  let colonie = Soin.colonie;
  let qtmedicament = Soin.qtmedicament || null;
  let medicament = null;
  { Soin.medicament == "" || Soin.medicament <= 0 ? medicament = null : medicament = Soin.medicament };
  let qtnourrissment = Soin.qtnourrissment || null;
  let unitenourrissement = null;
  { Soin.unitenourrissement == "" || Soin.unitenourrissement <= 0 ? unitenourrissement = null : unitenourrissement = Soin.unitenourrissement };
  let typnourrissement = Soin.typnourrissement || null;
  { Soin.typnourrissement == "" ? typnourrissement = null : typnourrissement = Soin.typnourrissement };
  let nourrissement = Soin.nourrissement || null;
  { Soin.nourrissement == "" || Soin.nourrissement <= 0 ? nourrissement = null : nourrissement = Soin.nourrissement };
  return put('/DetSoin/' + Soin.id, {
    date, colonie, qtmedicament, medicament, qtnourrissment,
    unitenourrissement, typnourrissement, nourrissement
  });
};

export const creeMultSoin = async (lesSoins) => {

  return post('/CreeMultSoin', { lesSoins });
};

// Comptage
export const getLastComptage = (idColonie) => {
  return get('/LastComptage?idColonie=' + idColonie);
};

export const getListComptage = (idColonie) => {
  return get('/ListComptage?idColonie=' + idColonie);
};

export const delComptage = async (Comptage) => {
  //   const Moment = require('moment');

  //   apiculteur = await getIdApiculteur();

  return del('/DetComptage/' + Comptage.id);
};


export const createComptage = async (Comptage) => {
  let date = Comptage.date;
  let colonie = Comptage.colonie;
  let methode = Comptage.methode
  let nombreabeilles = Comptage.nombreabeilles
  let nombrevarroas = Comptage.nombrevarroas

  return post('/NewComptage', { date, colonie, methode, nombreabeilles, nombrevarroas });

};

export const modifyComptage = async (Comptage) => {

  let date = Comptage.date;
  let colonie = Comptage.colonie;
  let methode = Comptage.methode
  let nombreabeilles = Comptage.nombreabeilles
  let nombrevarroas = Comptage.nombrevarroas

  return put('/DetComptage/' + Comptage.id, { date, colonie, methode, nombreabeilles, nombrevarroas });
};

export const creeMultComptage = async (lesComptages) => {

  return post('/CreeMultComptage', { lesComptages });
};


export const getListTypComptage = () => {
  return get('/ListTypComptage');
};

// Recolte
export const getLastRecolte = (idColonie) => {
  return get('/LastRecolte?idColonie=' + idColonie);
};

export const getListRecolte = (idColonie) => {
  return get('/ListRecolte?idColonie=' + idColonie);
};

export const createRecolte = async (Recolte) => {
  let date = Recolte.date;
  let nbkgmiel = Recolte.nbkgmiel;
  let colonie = Recolte.colonie;
  let miel = null;
  Recolte.miel == "" ? miel = null: miel = Recolte.miel; 
  let lieu = null;
  Recolte.lieu == "" ? lieu = null: lieu = Recolte.lieu;   
  return post('/NewRecolte', { date, nbkgmiel, colonie, miel, lieu });
};

export const modifyRecolte = async (Recolte) => {
  let date = Recolte.date;
  let nbkgmiel = Recolte.nbkgmiel;
  let colonie = Recolte.colonie;
  let miel = null;
  Recolte.miel == "" ? miel = null: miel = Recolte.miel;
  let lieu = null;
  Recolte.lieu == "" ? lieu = null: lieu = Recolte.lieu;

  return put('/DetRecolte/' + Recolte.id, { date, nbkgmiel, colonie, miel, lieu });
};

export const delRecolte = async (Recolte) => {

  return del('/DetRecolte/' + Recolte.id);
};




export const creeMultRecolte = async (lesRecoltes) => {

  return post('/CreeMultRecolte', { lesRecoltes });
};

// Observation

export const getLastObservation = (idColonie) => {
  return get('/LastObservation?idColonie=' + idColonie);
};

//Demenagement


export const getLastDemenagement = (idColonie) => {
  return get('/LastDemenagement?idColonie=' + idColonie);
}



