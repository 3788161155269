import React from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';



const CustomSwitchHoriz = (props) => {
  const {

    label, vSwitch,
    ...inputProps
  } = props


  return (
    <>
      {/*    <Grid style={styles.container}> */}

      <Grid container direction='row'>
        <Grid xs={2}>
          <Typography style={{ flex: 2 }} xs>{label}</Typography>
        </Grid>

        <Grid style={{ flex: 1, alignSelf: 'center' }} xsxs={2}>
          <Switch
            trackColor={{ false: "#767577", true: "#81b0ff" }}
            inputProps={{ 'aria-label': 'controlled' }}

            checked={vSwitch}
            {...inputProps}

          />
        </Grid>
      </Grid>

    </>
  )
}
/*
const styles = StyleSheet.create({
  textInput: {
    flex:3,  
    height: 30,
    width: '80%',
    margin: 5,
    backgroundColor: 'white',
    borderColor: 'gray',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
container: {
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  },
  label: {
    flex: 1 
  },
  
  errorText: {
    fontSize: 10,
    color: 'red',
  },
  errorInput: {
    borderColor: 'red',
  }
})
*/
export default CustomSwitchHoriz;
